// login.hook.js

import { useState } from "react";
import { LoginSchema } from "../../../components/input-fields/input-fields";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../provider/features/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../../provider/features/user-management/user-management.slice";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const auth = useSelector((state) => state.auth.login);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);

    const response = await dispatch(login({ formData }));
    if (response.payload.status === 200 && typeof (response.payload.data) === 'string') {
      await dispatch(getMe());
      localStorage.setItem("X-CSRFToken", response.payload.data)
      window.location.href = '/';
    } else {
      setErrorMessage("Invalid username or password. Please try again.");
    }
  };

  return {
    initialValues,
    showPassword,
    handleShowPassword,
    LoginSchema,
    onSubmit,
    auth,
    errorMessage,
  };
};
