import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { sidebarData } from ".";
import { SidebarWrapper } from "./style";

const Sidebar = ({ action }) => {
  const navigate = useNavigate();
  const [close, setClose] = useState(false);

  const { me } = useSelector((state) => state.userManagement);

  const handleSidebar = (arg) => {
    setClose(!arg);
    action(!arg);
  };

  return (
    <SidebarWrapper>
      <div className="sidebar">
        <div className="logo-details">
          <span className="logo_name">
            <a
              href={process.env.REACT_APP_ATOMEUS_WEBSITE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/images/sidebar/logoImg.png"
                alt=""
                height="75px"
              />
            </a>
          </span>
        </div>
        <ul className="nav-links">
          {sidebarData
            ?.filter((data) => {
              if (data.item === "User Management" && !me?.data?.is_key_user) {
                return false;
              }
              return true;
            })
            ?.map((data, index) => (
              <div
                key={index}
                className={
                  window.location.pathname == data.path
                    ? "sidebar-items-clicked"
                    : "sidebar-items"
                }
                onClick={() => {
                  navigate(data.path);
                }}
              >
                <li>
                  <a href="#">
                    <div className="items">
                      <img
                        src={data.icon}
                        alt=""
                        className={
                          window.location.pathname == data.path
                            ? "sidebar-images-clicked"
                            : "sidebar-images"
                        }
                      />
                      <span
                        className={
                          window.location.pathname == data.path
                            ? "link_name-clicked"
                            : "link_name"
                        }
                      >
                        {data.item}
                      </span>
                    </div>
                  </a>
                </li>
              </div>
            ))}
        </ul>
      </div>
      {/* <section className="">
        <div className="home-content">
          <h6 onClick={(previous) => setOpen(!previous)}>OK</h6>
        </div>
      </section> */}
    </SidebarWrapper>
  );
};

export default Sidebar;
