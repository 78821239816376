import { styled } from "styled-components";

export const RobotsListModalWrapper = styled.div`
  .editModal {
    top: 15px;
    min-height: 400px !important;
  }

  .topPartPadding {
    padding-top: 0px !important;
    padding-bottom: 34px !important;
  }

  .data_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    gap: 24px;
    padding: 24px 16px;
    background: #212036;
    border-radius: 5px;
  }

  .data_body {
    border-radius: 5px;
    background: #2c2b41;
    padding: 10px 20px 10px 20px;
    color: #99a2ac;
  }
}
`;
