import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { CardsWrapper } from "./style";
import { useDashboard } from "../charts/use-charts-hook";
import { cardDetail } from "../../../constants/landingpage/landing-page.constants";
import BeatLoader from "react-spinners/BeatLoader";

const Card = ({ user_id, bot_id, targetRef }) => {
  const navigate = useNavigate();
  const [rotation, setRotation] = useState(0);
  const [tooltipText, setTooltipText] = useState("See total time saved in EUR");
  const {
    loadingCard,
    cardData,
    displayTotalTimeSavedInEUR,
    setDisplayTotalTimeSavedInEUR,
    formatCardInfo,
    isKeyUser } = useDashboard({ user_id, bot_id });

  if (loadingCard) {
    return (
      <div className="spinner-container">
        <BeatLoader className="beat-loader" color="#123abc" />
      </div>
    );
  }

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  const handleToggle = () => {
    setDisplayTotalTimeSavedInEUR(!displayTotalTimeSavedInEUR);
    setRotation(rotation + 360);
    setTooltipText(displayTotalTimeSavedInEUR ? "See total time saved in EUR" : "See total time saved in hours");
  };

  return (
    <CardsWrapper>
      <div></div>
      <div className="cards">
        {cardDetail.filter((cd) => ((!isKeyUser && !cd.displayJustForKeyUser) || (isKeyUser))).map((card) => (
          <div className="card" key={card.id}>
            <div className="card-content">
              <div className="left-side">
                <div className="heading">
                  {displayTotalTimeSavedInEUR &&
                    card.id === 'totalTimeSavedCard' ?
                    card.longHeading :
                    card.heading}
                </div>
                <div className="users">{
                  `${(isNaN(cardData.firstFilter[card.field]) && typeof(cardData.firstFilter[card.field]) !== 'string' ) ||
                    !cardData.firstFilter[card.field] ?
                    0 :
                    (displayTotalTimeSavedInEUR
                      ? formatCardInfo(cardData.firstFilter[card.field], card.id, displayTotalTimeSavedInEUR).toLocaleString('nl-NL') // Formats with a comma as the decimal separator
                      : formatCardInfo(cardData.firstFilter[card.field], card.id, displayTotalTimeSavedInEUR))}
                    ${(!isNaN(cardData.firstFilter[card.secondField]) &&
                    cardData.firstFilter[card.secondField] !== undefined &&
                    cardData.firstFilter[card.secondField] !== null ?
                    ` / ${cardData.firstFilter[card.secondField]}` :
                    '')}`
                }</div>
                <div className="bottom">
                  <img
                    style={{
                      display: (isNaN(cardData.firstFilter[card.field]) ||
                        !cardData.firstFilter[card.changeField] ?
                        0 :
                        cardData.firstFilter[card.changeField]) === 0 ?
                        'none' :
                        'block'
                    }}
                    src={(isNaN(cardData.firstFilter[card.field]) ||
                      !cardData.firstFilter[card.changeField] ?
                      0 :
                      cardData.firstFilter[card.changeField]) >= 0 ?
                      card.arrow :
                      card.downArrow}
                    alt="arrow" />
                  <span
                    className={
                      (isNaN(cardData.firstFilter[card.field]) ||
                        !cardData.firstFilter[card.changeField] ?
                        0 :
                        cardData.firstFilter[card.changeField]) >= 0 ?
                        "percentage" :
                        "low-percentage"
                    }
                  >
                    {isNaN(cardData.firstFilter[card.changeField]) ||
                      !cardData.firstFilter[card.changeField] ?
                      0 :
                      cardData.firstFilter[card.changeField]}%
                  </span>
                  <div className="percentage-sub"> vs last month</div>
                </div>
              </div>
              <div>
                {card.showToggle && (
                  <div className="switch-toggle-position" title={tooltipText}>
                    <div
                      className={`svg-icon ms-12 ${displayTotalTimeSavedInEUR ? 'active' : ''}`}
                      onClick={handleToggle}
                      style={{ transform: `rotate(${rotation}deg)` }}
                    >
                      <img
                        src={`/assets/images/dashboard/euro_exchange${displayTotalTimeSavedInEUR ? '_active' : ''}.svg`}
                        alt="Currency Exchange Icon"
                        width="40"
                        height="40"
                        style={{ transform: 'scale(1.3)' }}
                      />
                    </div>
                  </div>
                )}
                <div className="image" style={{ cursor: card.navigateTo ? 'pointer' : '' }}>
                  <img
                    src={card.icon}
                    alt="icon"
                    onClick={() => { card.navigateTo === 'targetRef' ? handleScroll() : navigate(card.navigateTo) }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </CardsWrapper>
  );
};

export default Card;
