import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { ModalWrapper } from "../style";
import { RobotsListModalWrapper } from "./robotsListStyle"
import UsernameIcon from "../../../common/components/username-icon/username-icon";
import { getRobot } from "../../../provider/features/robot-management/robot-management.slice";

const RobotListModal = ({ show, action, user }) => {
  const [robots, setRobots] = useState([]);

  const dispatch = useDispatch();

  const handleModalClose = () => {
    action();
  };

  useEffect(() => {
    // Use this to load all bots once all bots can be displayed
    // getRobots();

    async function getRobots() {
      const robots = await dispatch(getRobot(user?.rights?.map((right) => right.bot)));
      setRobots(robots?.payload?.data);
    }
  }, [user, dispatch]);

  return (
    <Modal show={show} onHide={handleModalClose} backdrop="static">
      <ModalWrapper>
        <RobotsListModalWrapper>
          <div className="modal editModal">
            {/* Header */}
            <div className="topPart topPartPadding">
              <div className="user">
                <div className="userIconDiv">
                  <UsernameIcon user={user} />
                </div>
                <div className="details">
                  <span className="first">{user?.first_name} {user?.last_name}</span>
                  <span className="second">{user?.email}</span>
                </div>
              </div>
              <div className="crossIconDiv" onClick={handleModalClose}>
                <img
                  src="/assets/images/modal/cross.svg"
                  className="crossIcon"
                  alt="Close"
                />
              </div>
            </div>
            {/* Robots list */}
            <div className="data_wrapper p-16 mt-14-negative mb-14-negative">
              <div>Bots Assigned</div>
              <div className="data_body">
                {/* Currently just display the bot code */}
                {user?.rights?.map((right, index) => (
                  <div key={index}>
                    {index + 1}. {right?.right_code?.split(" for ")[0]}
                  </div>
                ))}
                {/* TODO: Use this approach once all bots can be displayed */}
                {/* {robots?.map((robot, index) => (
                  <div key={index}>
                    {index + 1}. {robot?.bot_name}
                  </div>
                ))} */}
                {/* Display message if no bots are assigned */}
                {user?.rights?.length === 0 && <div>No bots assigned</div>}
              </div>
            </div>
          </div>
        </RobotsListModalWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default RobotListModal;
