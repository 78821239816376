import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserManagement from "../../../components/management/userManagement/userManagement";
import { ManageUserWrapper } from "./style";

const ManageUser = () => {
  const [layout, setLayout] = useState("");
  const navigate = useNavigate();

  const { me } = useSelector((state) => state.userManagement);

  // Loading state to prevent rendering until role is determined
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (me) {
      if (me.data && !me.data?.is_key_user) {
        console.log("INTRA");
        navigate("/generic-error");
      } else {
        setIsLoading(false);
      }
    }
  }, [me, navigate]);

  if(isLoading){
    return; 
  }

  const handburger = (arg) => {
    setLayout(arg);
  };

  return (
    <div className={layout ? "mainWrapper collapsed-layout" : "mainWrapper"}>
      <ManageUserWrapper>
        <div>
        </div>
        <div>
          <UserManagement />
        </div>
      </ManageUserWrapper>
    </div>
  );
};

export default ManageUser;
