import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { React, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Bar } from "react-chartjs-2";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { options_NPS } from "./chart-constant";
import { ChartWrapper } from "./style";
import { useDashboard } from "./use-charts-hook";
import BeatLoader from "react-spinners/BeatLoader";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Chart = ({ user_id, bot_id, targetRef }) => {
  const {
    loadingChart,
    dataFTE,
    dataNPS,
    cumulativeNPS,
    chartUnit,
    setChartUnit,
    chartOptions,
    monthlyDataFTE } = useDashboard({ user_id, bot_id });

  useEffect(() => {
    const handleResize = () => {
      if (targetRef.current && targetRef.current.chartInstance) {
        targetRef.current.chartInstance.resize(); // Chart.js 2.x instance
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (loadingChart) {
    return (
      <div className="spinner-container">
        <BeatLoader className="beat-loader" color="#123abc" />
      </div>
    );
  }

  return (
    <ChartWrapper style={{height: 'auto'}}>
      <div className="containerClass">
        <div style={{ width: '100%', display: 'flex' }}>
          <div className="chartBorder" ref={targetRef}>
            <div className="top-side">
              <div className="heading-one">Productivity in {chartUnit}</div>
              <div className="dropdown-class">
                <img
                  src="/assets/images/dashboard/calendar.svg"
                  className="calendar-icon"
                  alt=""
                />
                <DropdownButton id="dropdown-basic-button" title={chartUnit}>
                  <Dropdown.Item onClick={() => { setChartUnit('Hours'); }}>Hours</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setChartUnit('Minutes'); }}>Minutes</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setChartUnit('EUR'); }}>EUR</Dropdown.Item>
                </DropdownButton>

                <img
                  src="/assets/images/dashboard/arrow-down.svg"
                  className="arrow-down-icon"
                  alt=""
                />
              </div>
            </div>
            <div className="graph">
              <Bar options={chartOptions} data={dataFTE} />
            </div>
          </div>

          <div style={{ width: '2%' }}></div>

          <div className="chartBorder-right-side">
            <div style={{ width: '100%', display: 'flex' }}>
              <div className="heading-one" style={{ width: '50%' }}>Month</div>
              <div className="heading-one" style={{ width: '50%' }}>Time</div>
            </div>
            {monthlyDataFTE.map((info) => (
              <div style={{ width: '100%', display: 'flex' }}>
                <div className="monthly-data-sub">{info.month}</div>
                <div className="monthly-data-sub">{info.value}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="chartBorder-two" style={{ display: 'none' }}>
          <div className="leftPart">
            <div className="nps">NPS For Organization</div>

            <div style={{ width: 252, height: 252 }} className="progressBar">
              <CircularProgressbar
                value={cumulativeNPS}
                maxValue={1}
                text={`+${cumulativeNPS * 100}`}
              />
            </div>
          </div>
          <div className="bottom-graph">
            <div className="heading-one">
              Net Promoter Score (NPS) Over Time
            </div>
            <div className="graph-content">
              <Bar options={options_NPS} data={dataNPS} />
            </div>
          </div>
        </div>
      </div>
    </ChartWrapper>
  );
};

export default Chart;
