import api from "../../../common/utils/api";

const login = async (data) => {
  const response = await api().post("user-management/login/", data);
  return response;
};

const forgotPassword = async (data) => {
  const response = await api().post("user-management/forgot-password/", data);
  return response;
};

const resetPassword = async (signed_pw_reset_request_at, signed_pk, data) => {
  const response = await api().put(
    `user-management/recover-password/${signed_pw_reset_request_at}/${signed_pk}/`,
    data
  );
  return response;
};

const logout = async () => {
  const response = await api().post("user-management/logout/");
  return response;
};

const changePassword = async (data) => {
  console.log(data);
  const response = await api().put("user-management/change-password/", data);
  return response;
}
const registerUser = async (signedpk, data) => {
  const response = await api().put(`user-management/welcome/${signedpk}/`, 
    {
      password: data.password,
      repeat_password: data.confirmPassword,
    }
  );
  return response;
};

const authService = {
  login,
  forgotPassword,
  resetPassword,
  logout,
  changePassword,
  registerUser,
};

export default authService;
