import axios from "axios";
import { enqueueSnackbar } from "notistack";
import Cookie from "js-cookie";

const api = () => {
  axios.defaults.withCredentials = true;
  axios.defaults.xsrfCookieName = "csrftoken";

  const apiSet = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_MAIN_URL,
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": localStorage.getItem("X-CSRFToken"),
      withCredentials: true,
    },
  });

  apiSet.interceptors.response.use(
    async (response) => {
      const method = response.config?.method || "";

      if (["get", "post", "patch", "delete"].includes(method)) {
        // await delay(700);
        return response;
      }
      return response;
    },
    (error) => {
      console.error(error);
      if (error.message === "Network Error") {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        throw error;
      }
      if (error.response.status === 403 && typeof window === "object") {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      let { data } = error.response;
      if (!data) {
        data =
          (error.response && error.response.data && error.response.data.data) ||
          error.data ||
          error.toString();
      }

      if (Array.isArray(data)) {
        data.forEach((element) => {
          enqueueSnackbar(element, {
            variant: "error",
          });
        });
      } else {
        if (data !== "Record Not Found" && !data.startsWith("There is no available seat left")) {
          enqueueSnackbar("Got Error", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Error", {
            variant: "error",
          });
        }
      }
      return error.response;
    }
  );

  return apiSet;
};

export default api;
