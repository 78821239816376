import { styled } from "styled-components";

export const ChartWrapper = styled.div`
  padding: 0 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%; 
  .containerClass {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .chartBorder,
  .chartBorder-right-side {
    border: 1px solid #99a2ac;
    padding: 41px 25px;
    border-radius: 8px;
    background: #212036;
    display: flex;
    flex-direction: column;
  }

  .chartBorder {
    flex: 4;
    width: 80%;
    min-height: 100%; 
    display: flex;
    flex-direction: column;
  }

  .graph {
    width: 100%;
    min-height: 100%; 
    aspect-ratio: 2 / 1;
    display: flex;
    align-items: stretch;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
    padding: 10px;
    padding-bottom: 30px;
  }

  .chartBorder-right-side {
    flex: 1;
    min-width: 18%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(40px, auto);
    gap: 10px;
    justify-content: space-between;
    align-items: start;
  }

  .top-side {
    display: flex;
    justify-content: space-between;
  }

  .beat-loader {
    text-align: center;
    color: #123abc;
    margin: 0 auto;
  }

  .btn-primary {
    border: 1px solid #99a2ac;
    border-radius: 8px;
    background: #212036;
    width: 141px;
  }

  .dropdown-class {
    display: flex;
    position: relative;
  }

  .calendar-icon,
  .arrow-down-icon {
    position: absolute;
    z-index: 1;
    width: 16px;
    height: 16px;
  }

  .calendar-icon {
    top: 11px;
    left: 11px;
  }

  .arrow-down-icon {
    top: 11px;
    right: 11px;
  }

  .heading-one {
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #f4f6f8;
  }

  .chartBorder-two {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #99a2ac;
    padding: 32px;
    gap: 32px;
    border-radius: 8px;
    background: #212036;

    .leftPart {
      display: flex;
      flex-direction: column;
      gap: 72px;
      align-items: center;
    }
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #2c2b41;
    border: 28px solid #2c2b41;
  }
  .CircularProgressbar .CircularProgressbar-path {
    stroke: #45e8e1 !important;
  }
  .CircularProgressbar .CircularProgressbar-text {
    fill: #f4f6f8;
  }

  .bottom-graph {
    width: 100%;
    padding: 0 30px;
  }

  .response-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #99a2ac;
    transform: rotate(-90deg);
    position: absolute;
    left: -43px;
    top: 15rem;
  }

  .graph-content {
    display: flex;
    justify-content: center;
  }

  .month-text {
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #99a2ac;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .monthly-data-sub {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #99a2ac;
    width: 50%;
    height: 5.4vh;
  }

  @media (max-width: 1200px) {
    .progressBar {
      padding-top: 0 !important;
    }
    .bottom-graph {
      width: 80% !important;
      padding: 0 !important;
    }
    .leftPart {
      gap: 32px !important;
    }
  }

  @media (max-width: 900px) {
    .containerClass {
      flex-direction: column;
      align-items: center;
    }
    .chartBorder {
      width: 100%;
      min-height: 200px;
    }
    .chartBorder-right-side {
      width: 100%;
      order: 2;
    }
    .chartBorder-two {
      flex-direction: column;
      gap: 32px;
      align-items: center;
    }
    .bottom-graph {
      width: 100% !important;
      padding: 0 !important;
    }
  }
`;

