import api from "../../../common/utils/api";

const registerKey = async (data) => {
  try {
    const response = await api().post("user-management/register-key/", data);

    // Code 400 - Bad Request means email allready registered
    if (response.status >= 400) {
      if (response.data && response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error(`Error ${response.status}: ${response.data?.message || "An error occurred."}`);
      }
    }

    return response.data;
  } catch (error) { 
    throw new Error(error.message || "An unexpected error occurred.");
  }
}

const listUsers = async (params) => {
  const response = await api().get("user-management/", {
    params,
    withCredentials: true
  });
  return response.data;
};

const getMe = async () => {
  const response = await api().get("user-management/me/");
  return response;
};

const putMe = async (data) => {
  const response = await api().put("user-management/me/", data);
  return response;
}

const deleteUser = async (id) => {
  const response = await api().delete(`user-management/${id}/`);
  return response;
}

const updateUser = async (data) => {
    const response = await api().put(`user-management/${data.user_id}/`, data);
    return response.data;
}

const getUser = async (id) => {
  const response = await api().get(`user-management/${id}/`);
  return response;
}

const userManagementServices = {
  registerKey,
  listUsers,
  getMe,
  putMe,
  deleteUser,
  updateUser,
  getUser,
};

export default userManagementServices;
