import "./App.css";
import Home from "./pages/landingpage/home";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Sidebar from "./common/sidebar/sidebar";
import Navbar from "./common/navbar/navbar";
import Login from "./pages/auth/login/login";
import ForgotPassword from "./pages/auth/forgot-password/forgot-password";
import ResetPassword from "./pages/auth/reset-password/reset-password";
import Register from "./pages/auth/register/register";
import WatchDemo from "./pages/demo";

import ManageUser from "./pages/management/user-management";
import ManageRobot from "./pages/management/robot-management";
import ErrorPage from "./pages/error/error-page";
import GenericErrorPage from "./pages/error/generic-error-page";
import SupportPage from "./pages/support";

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getMe } from "./provider/features/user-management/user-management.slice";

function App() {
  // List of routes where Sidebar and Navbar should not be rendered
  const excludedRoutes = [
    "/",
    "/register",
    "/login",
    "/forgot-password",
    "/watch-demo",
    "/user-management",
    "/robot-management",
    "/reset-password",
    "/error",
    "/generic-error",
    "/support"
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPathRequiresAuth()) {
      loadAuthenticatedUser();
    }
  });

  return (
    <>
      <Router>
        <TitleUpdater />
        {showNavigationBars() ? (
          <>
            <Sidebar />
            <Navbar />
          </>
        ) : null}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/user-management/welcome/:signedpk" element={<Register />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/user-management/recover-password/:signed_pw_reset_request_at/:signed_pk" element={<ResetPassword />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/watch-demo" element={<WatchDemo />} />
          <Route exact path="/user-management" element={<ManageUser />} />
          <Route exact path="/robot-management" element={<ManageRobot />} />
          <Route exact path="/support" element={<SupportPage />} />
          <Route exact path="/error" element={<ErrorPage />} />
          <Route path="*" element={<Navigate to="/error" />} />
          <Route exact path="/generic-error" element={<GenericErrorPage />} />
        </Routes>
      </Router>
    </>
  );

  function currentPathRequiresAuth() {
    const pathname = window.location.pathname;
    const routesWithoutAuth = [
      "/login",
      "/forgot-password",
      "/generic-error",
      "/reset-password",
      "/error",
    ];
    const isWelcomePage = /^\/user-management\/welcome\/.+$/.test(pathname);
    const isRecoverPasswordPage = /^\/user-management\/recover-password\/.+/.test(pathname);

    return !routesWithoutAuth.includes(pathname) && !isWelcomePage && !isRecoverPasswordPage;
  }

  function showNavigationBars() {
    const pathname = window.location.pathname;
    const routesWithoutNav = [
      "/login",
      "/forgot-password",
      "/watch-demo",
      "/reset-password"
    ];
    const isWelcomePage = /^\/user-management\/welcome\/.+$/.test(pathname);
    const isRecoverPasswordPage = /^\/user-management\/recover-password\/.+/.test(pathname);

    return !routesWithoutNav.includes(pathname) && !isWelcomePage && !isRecoverPasswordPage;
  }

  async function loadAuthenticatedUser() {
    const result = await dispatch(getMe());
    if (result.payload.status !== 200) {
      if (result.payload.status === 403) {
        window.location.href = "/login";
      } else {
        console.error("Error loading user: ", result.data);
        // TODO: navigate to error page
      }
    }
  }
}

export default App;

const TitleUpdater = () => {
  const location = useLocation();

  // Map of paths to titles
  // If a path is a substring of another path, the longer path should come first !!!!!
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routeTitles = {
    "/login": "Login",
    "/user-management/welcome": "Register",
    "/forgot-password": "Forgot Password",
    "/reset-password": "Reset Password",
    "/dashboard": "Dashboard",
    "/watch-demo": "Watch Demo",
    "/user-management": "User Management",
    "/robot-management": "Robot Management",
    "/error": "Error 404",
    "/generic-error": "Error",
    "/support": "Support",
    "/": "Home",
  };

  useEffect(() => {
    document.title = "Atomeus Portal";
    Object.entries(routeTitles).forEach(([key, value]) => {
      if (location.pathname.includes(key) && document.title === "Atomeus Portal") {
        document.title = `Atomeus Portal - ${value}`;
      }
    });
    if (document.title === "Atomeus Portal") {
      document.title = "Atomeus Portal - Page Not Found";
    }
  }, [location, routeTitles]); // Runs every time location changes

  return null; // This component doesn't render anything visually
};