import { styled } from "styled-components";

export const CardsWrapper = styled.div`
.switch-toggle-position {
  margin-top: -2.7rem;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
}


.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.4s ease, filter 0.4s ease;
}

.svg-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}
  .ms-12 {
    margin-left: 12px;
  }


  .cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    justify-content: space-between;
    padding: 14px 36px 24px 36px;
    gap: 20px;
    z-index: -1;
}

    @media (max-width: 1650px) {
    .cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .card {
    padding: 24px;
    background: #212036;
    border: 1px solid #99a2ac;
    border-radius: 8px;
    min-width: 300px;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-side {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .heading {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #99a2ac;
    }

    .users {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 150%;
      color: #f4f6f8;
    }

    .bottom {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .percentage {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #1fcb4f;
    }

    .low-percentage {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #cb1f34;
    }

    .percentage-sub {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #99a2ac;
    }
  }
`;
