
const cardDetail = [
  {
    id: "totalTimeSavedCard",
    heading: "Total Time Saved",
    longHeading: "Total Time Saved (in EUR)",
    field: "rolling_time_saved",
    changeField: "rolling_time_saved_change",
    navigateTo: "targetRef",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/time-monitoring.svg",
    showToggle: true,
  },
  {
    id: "usersThisMonthCard",
    heading: "Users This Month",
    field: "rolling_user_no",
    changeField: "rolling_active_user_no_change",
    navigateTo: "/user-management",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/user-group.svg",
    displayJustForKeyUser: true,
  },
  {
    id: "robotsThisMonthCard",
    heading: "Seats This Month",
    field: "no_active_seats",
    secondField: "no_available_seats",
    changeField: "rolling_no_active_seats",
    navigateTo: "/robot-management",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/robot.svg",
    displayJustForKeyUser: true,
  },
  {
    id: "successfulJobsThisMonthCard",
    heading: "Successful Jobs This Month",
    field: "rolling_successful_job_no",
    secondField: "rolling_job_no",
    changeField: "rolling_successful_job_no_change",
    // navigateTo: "/job-management",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/succesfull-monitoring.svg",
  },
];

export {
  cardDetail
};
