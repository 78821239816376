import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Menu } from "@material-ui/core";

import { NavbarWrapper } from "./style";
import LogoutModal from "../../components/modal/logoutModal";
import UsernameIcon from "../components/username-icon/username-icon";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const user = useSelector((state) => state.userManagement.me);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openEL = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavbarWrapper>
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container-fluid justify-content-between">
          <div className="d-flex"></div>
          <div className="navbar-nav flex-row right-items">
            <div className="localization">
              <span>EN</span>
              <img
                src="/assets/images/sidebar/arrow-dropdown-gray.svg"
                className="arrow-dropdown-gray"
                alt=""
              />
            </div>
            <li className="nav-item me-3 me-lg-1">
              <a
                onClick={handleClick}
                className="profile nav-link d-sm-flex align-items-sm-center"
                href="#"
              >
                <UsernameIcon user={user?.data}/>
              </a>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                onClose={handleClose}
                open={openEL}
                className="more"
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <LogoutModal closeMenu={handleClose}/>
              </Menu>
            </li>
          </div>
        </div>
      </nav>
    </NavbarWrapper>
  );
};

export default Navbar;
