import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import userManagementServices from './user-management.service';

const initialState = {
  registerKey: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  allUsers: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  me: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  deleteUser: {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  updateUser: {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
};

export const registerKey = createAsyncThunk(
  'userManagement/registerKey',
  async (data, thunkAPI) => {
    try {
      const response = await userManagementServices.registerKey(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const listUsers = createAsyncThunk(
  'userManagement/getAllUsers',
  async (params, thunkAPI) => {
    try {
      const response = await userManagementServices.listUsers(params);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getUser = createAsyncThunk(
  'userManagement/getUser',
  async ({ user_id, successCallBack }, thunkAPI) => {
    try {
      const response = await userManagementServices.getUser(user_id);
      return successCallBack(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getMe = createAsyncThunk(
  'userManagement/getMe',
  async (thunkAPI) => {
    try {
      const response = await userManagementServices.getMe();
      return {...response.data, status: response.status};
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const putMe = createAsyncThunk(
  'userManagement/putMe',
  async ({ formData }, thunkAPI) => {
    try {
      const response = await userManagementServices.putMe(formData);
      return {...response.data, status: response.status};
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const deleteUser = createAsyncThunk(
  'userManagement/deleteUser',
  async (id, thunkAPI) => {
    try {
      const response = await userManagementServices.deleteUser(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const updateUser = createAsyncThunk(
  'userManagement/updateUser',
  async (data, thunkAPI) => {
    try {
      const response = await userManagementServices.updateUser(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const usersManagementSlice = createSlice({
  name: 'usersManagementSlice',
  initialState,
  reducers: {
    reset: (state) => {
      state.getUser = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: '',
      };
      state.me = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: '',
      };
      state.updateUser = {  // Ensure this exists
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // registerKey
      .addCase(registerKey.pending, (state) => {
        state.registerKey.isLoading = true;
        state.registerKey.message = "";
        state.registerKey.isError = false;
        state.registerKey.isSuccess = false;
        state.registerKey.data = null;
      })
      .addCase(registerKey.fulfilled, (state, action) => {
        state.registerKey.isLoading = false;
        state.registerKey.isSuccess = true;
        state.registerKey.data = action.payload;
      })
      .addCase(registerKey.rejected, (state, action) => {
        state.registerKey.message = action.payload;
        state.registerKey.isLoading = false;
        state.registerKey.isError = true;
        state.registerKey.data = null;
      })
      // getAllUsers
      .addCase(listUsers.pending, (state) => {
        state.allUsers.isLoading = true;
        state.allUsers.message = "";
        state.allUsers.isError = false;
        state.allUsers.isSuccess = false;
        state.allUsers.data = null;
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.allUsers.isLoading = false;
        state.allUsers.isSuccess = true;
        state.allUsers.data = action.payload;
      })
      .addCase(listUsers.rejected, (state, action) => {
        state.allUsers.message = action.payload;
        state.allUsers.isLoading = false;
        state.allUsers.isError = true;
        state.allUsers.data = null;
      })
      // getMe
      .addCase(getMe.pending, (state) => {
        state.me.isLoading = true;
        state.me.message = "";
        state.me.isError = false;
        state.me.isSuccess = false;
        state.me.data = null;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.me.isLoading = false;
        state.me.isSuccess = true;
        state.me.data = action.payload;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.me.message = action.payload;
        state.me.isLoading = false;
        state.me.isError = true;
        state.me.data = null;
      })
      // putMe
      .addCase(putMe.pending, (state) => {
        state.me.isLoading = true;
        state.me.message = '';
        state.me.isError = false;
        state.me.isSuccess = false;
        state.me.data = null;
      })
      .addCase(putMe.fulfilled, (state, action) => {
        state.me.isLoading = false;
        state.me.isSuccess = true;
        state.me.data = action.payload;
      })
      .addCase(putMe.rejected, (state, action) => {
        state.me.message = action.payload;
        state.me.isLoading = false;
        state.me.isError = true;
        state.me.data = null;
      })
      // deleteUser
      .addCase(deleteUser.pending, (state) => {
        state.deleteUser.isLoading = true;
        state.deleteUser.message = "";
        state.deleteUser.isError = false;
        state.deleteUser.isSuccess = false;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUser.isLoading = false;
        state.deleteUser.isSuccess = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUser.message = action.payload;
        state.deleteUser.isLoading = false;
        state.deleteUser.isError = true;
      })
      // udpateUser
      .addCase(updateUser.pending, (state) => {
        state.updateUser.isLoading = true;
        state.updateUser.message = "";
        state.updateUser.isError = false;
        state.updateUser.isSuccess = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUser.isLoading = false;
        state.updateUser.isSuccess = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUser.message = action.payload;
        state.updateUser.isLoading = false;
        state.updateUser.isError = true;
      })
      // getUser
      .addCase(getUser.pending, (state) => {
        state.deleteUser.isLoading = true;
        state.deleteUser.message = "";
        state.deleteUser.isError = false;
        state.deleteUser.isSuccess = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.deleteUser.isLoading = false;
        state.deleteUser.isSuccess = true;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.deleteUser.message = action.payload;
        state.deleteUser.isLoading = false;
        state.deleteUser.isError = true;
      });
  },
});

export const { reset } = usersManagementSlice.actions;

export default usersManagementSlice.reducer;
